import Link from 'next/link';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

export const TopBar = () => {
    const [show, setShow] = useState(false);
    const showDropdown = () => {
        setShow(!show);
    };
    const hideDropdown = () => {
        setShow(false);
    };
    return (
        <div className="bg-navy text-white fw-bold fs-14" id="topbar">
            <div className="container d-flex flex-row mx-lg-row mx-xl-auto py-lg-1">
                <div className="d-md-flex flex-lg-row flex-wrap header-subnav">
                    <Dropdown
                        id="collasible-nav-dropdown"
                        show={show}
                        onMouseEnter={showDropdown}
                        onMouseLeave={hideDropdown}
                    >
                        <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className="ps-1 fs-14  d-flex align-items-center justify-content-center"
                        >
                            <span className="text-hover">
                                Managed Networks
                            </span>
                            <i
                                className="uil uil-angle-down fs-24 "
                                id="icon-down"
                            ></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="topbar-dropdown-menu">
                            <Dropdown.Item
                                href="https://teacoprovidernetwork.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="custom-dropdown-item"
                            >
                                TEACO Provider Network
                            </Dropdown.Item>
                            <Dropdown.Item
                                href="https://vanlangipa.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="custom-dropdown-item"
                            >
                                Van Lang IPA
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="bottom-bar-navigation d-md-flex flex-lg-row justify-content-end flex-wrap ms-auto">
                    <div className="d-flex flex-row align-items-center ms-6 bottom-bar-item">
                        <p className="mb-0">
                            <a
                                href="https://provider.tecqpartners.com/tecq/fad"
                                target="_blank"
                                rel="noreferrer"
                                className="link-white hover"
                                tabIndex={0}
                                data-bs-toggle="popover"
                                data-bs-trigger="hover"
                                data-bs-placement="bottom"
                                title=""
                                data-bs-custom-class="sub-navbar-popover-overlay"
                                data-bs-content="Find providers in the area and get their information"
                            >
                                <span className="icon text-white me-1 top-bar--icon">
                                    <i className="uil uil-external-link-alt" />
                                </span>
                                Find a Doctor
                            </a>
                        </p>
                    </div>
                    <div className="d-flex flex-row align-items-center ms-6  bottom-bar-item">
                        <p className="mb-0">
                            <a
                                href="https://payer.tecqpartners.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="link-white hover"
                                tabIndex={0}
                                data-bs-toggle="popover"
                                data-bs-trigger="hover"
                                data-bs-placement="bottom"
                                title=""
                                data-bs-custom-class="sub-navbar-popover-overlay"
                                data-bs-content="Register here for pre-authorization and claims"
                            >
                                <span className="icon text-white me-1 top-bar--icon">
                                    <i className="uil uil-external-link-alt" />
                                </span>
                                Payer System
                            </a>
                        </p>
                    </div>
                    <div className="d-flex flex-row align-items-center ms-6 bottom-bar-item">
                        <p className="mb-0">
                            <a
                                href="https://provider.tecqpartners.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="link-white hover"
                                tabIndex={0}
                                data-bs-toggle="popover"
                                data-bs-trigger="hover"
                                data-bs-placement="bottom"
                                title=""
                                data-bs-custom-class="sub-navbar-popover-overlay"
                                data-bs-content="Register here for EFT and document submissions"
                            >
                                <span className="icon text-white me-1 top-bar--icon">
                                    <i className="uil uil-external-link-alt" />
                                </span>
                                Provider Login
                            </a>
                        </p>
                    </div>
                    <div className="d-flex flex-row align-items-center ms-6 pe-lg-2 pe-xl-4 bottom-bar-item">
                        <p className="mb-0">
                            <Link href="/page/support">
                                <a className="link-white hover">
                                    <span className="icon text-white me-1 top-bar--icon">
                                        <i className="uil uil-headphones-alt"></i>
                                    </span>
                                    Support
                                </a>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
