import { Site, TMenu } from '@app/model';
import { createContext, ReactNode, useState } from 'react';

type AppActionType = 'PREVIEW';
export type AppContextType = {
    generalData: Site;
    menuData: TMenu[];
    metaData?: {
        quickLinks?: TMenu[];
    };
    isPreviewSite: boolean;
    // eslint-disable-next-line no-unused-vars
    dispatch?: (arg0: { type: AppActionType; payload?: any }) => void;
}

export const AppContext = createContext<AppContextType>({
    generalData: {} as Site,
    menuData: [],
    isPreviewSite: false,
});


export const AppProvider = ({ children, value }: { children: ReactNode; value: any }) => {
    const [isPreviewSite, setIsPreviewSite] = useState(false);

    const dispatch = ({ type, payload }: { type: AppActionType; payload?: any }) => {
        switch (type) {
            case 'PREVIEW':
                setIsPreviewSite(payload || false);
                break;
            default:
                break;
        }
    };

    const appContextValue = { ...value, isPreviewSite, dispatch } as AppContextType;
    return <AppContext.Provider value={appContextValue}>
        {children}
    </AppContext.Provider>;

};