import { useAppData } from '@app/hooks';
import { Site } from '@app/model';
import React from 'react';

type FooterLayoutType = Pick<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
    'children'
> & {
    generalData: Site;
};

export const FooterLayout = ({ generalData }: FooterLayoutType) => {
    const currentYear = new Date().getFullYear();

    const { metaData: { quickLinks = [] } = {} } = useAppData();

    return (
        <footer className="text-inverse shadow pt-8 pt-md-14 pb-6 pt-md-14" id="footer-custom">
            <div className="container">
                <div className="row gy-6 gy-lg-0">
                    <div className="col-6 col-sm-8 col-lg-3">
                        <div className="widget">
                            <h4 className="widget-title  mb-3 text-white ">Contact Us</h4>
                            <address className="">
                                {generalData?.office_address}<br />
                                {generalData?.office_city}, {generalData?.office_state} {generalData?.office_zip}<br />
                                {generalData?.country}
                            </address>
                            <a
                                href={`mailto:${generalData?.email}`}
                                className="link-body hover"
                            >
                                {generalData?.email}
                            </a>
                            <br /> <a className='hover' href={`tel:${`${generalData?.phone_number}`}`}>{`${generalData?.phone_number}`}</a>
                        </div>
                        <p className="mb-4 mt-4">
                            © {currentYear} <b>{generalData?.name}</b>.{' '}
                            <br className="d-none d-lg-block" />
                            All rights reserved.
                        </p>
                    </div>
                    <div className="col-6 col-sm-4 col-lg-3">
                        <div className="widget">
                            <h4 className="widget-title  mb-3 text-white ">Read More</h4>
                            <ul className="list-unstyled text-reset mb-0">
                                {quickLinks.map((link, index: number) => {
                                    const current_link = link?.link?.length
                                        ? link.link
                                        : `/category/${link.slug}`;
                                    return (
                                        <li key={index}>
                                            <a href={current_link} className='hover'>{link.title}</a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <iframe
                            className="shadow-sm rounded"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d866.3734142153965!2d-95.53185457147241!3d29.70545649843702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c2ee335f185d%3A0x8335a79896f3b633!2s8278%20Bellaire%20Blvd%20%23%20B%2C%20Houston%2C%20TX%2077036!5e0!3m2!1sen!2sus!4v1683168986850!5m2!1sen!2sus"
                            width="100%"
                            height="300"
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
                <div className='row mt-8'>
                    <p className='text-center'>{generalData?.summary_info}</p>
                </div>
            </div>
        </footer>
    );
};
