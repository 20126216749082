import React, { useEffect, useState } from 'react';

import classnames from 'classnames';

import { HeaderLayout, FooterLayout } from '@app/@gotecq-cms/layout';
import { Site, TMenu } from '@app/model';
import { PreviewMode } from '@app/components/common/Preview';
import { CMSBaseURL, LocalStorageKey, PreviewModeURL } from '@app/constants';
import Link from 'next/link';
import { useAppData } from '@app/hooks';

export type MainLayout = {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    layoutProps: {
        wrapperClassName?: string;
        menuData: TMenu[];
        generalData: Site;
        hideFooter?: boolean;
    };
    CMSInfo?: {
        slug?: string;
        id?: string;
    };
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

export const MainLayout = ({
    header,
    footer,
    className,
    children,
    CMSInfo,
    layoutProps: { wrapperClassName, menuData, generalData, hideFooter },
    ...rest
}: MainLayout) => {
    const [isPreviewEnable, setIsPreviewEnable] = useState(false);
    const [isPreviewEnableDefault, setIsPreviewEnableDefault] = useState(true);
    const cmsEditURL = `${CMSBaseURL}/${CMSInfo?.slug}/${CMSInfo?.id}`;
    const { dispatch } = useAppData();

    useEffect(() => {
        const previewMode = localStorage.getItem(LocalStorageKey.preview);
        if (previewMode !== null) {
            setIsPreviewEnableDefault(previewMode === 'true' ? true : false);
        }
    }, []);

    useEffect(() => {
        const { origin } = window.location;
        const isPreviewEnable = PreviewModeURL?.some((path) => path === origin);
        setIsPreviewEnable(isPreviewEnable);
        dispatch && dispatch({ type: 'PREVIEW', payload: isPreviewEnable });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className={classnames('content-wrapper', wrapperClassName)}
            {...rest}
        >
            {header || <HeaderLayout menuData={menuData} />}
            {/* <!-- main --> */}
            <main className={classnames('wrapper bg-light', className)}>
                {children}
            </main>
            {/* <!-- footer --> */}
            {(footer) || ((generalData && !hideFooter) && <FooterLayout generalData={generalData} />)}
            {isPreviewEnable &&
                <PreviewMode defaultEnable={isPreviewEnableDefault} >
                    {CMSInfo && <Link href={cmsEditURL}>
                        <a target='_blank' className='cms-edit-btn btn btn-sm btn-gradient gradient-7 rounded-pill'>Edit on CMS</a>
                    </Link>}
                </PreviewMode>
            }
        </div>
    );
};
