import { QUERY_API } from '@app/access';
import { APP_URL, LocalStorageKey } from '@app/constants';
import React, { ReactNode, useEffect, useState } from 'react';
import { Loading } from '../atom/loading';

export const PreviewMode = ({ defaultEnable = false, children }: { defaultEnable?: boolean; children: ReactNode }) => {
    const [isPreviewEnable, setIsPreviewEnable] = useState<boolean>(defaultEnable);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleActionBtnClick = async () => {
        setIsLoading(true);
        try {
            const { data } = await QUERY_API
                .preview
                .action(isPreviewEnable ? APP_URL.clearPreview : APP_URL.preview);
            setIsPreviewEnable(data.isEnabled);
            setIsLoading(false);
            localStorage.setItem(LocalStorageKey.preview, `${data.isEnabled}`);
        } catch (error) {
            setIsLoading(false);
            setIsError(true);
        }
    };

    useEffect(() => {
        let isCancelled = false;
        setIsLoading(true);
        (async () => {
            try {
                if (defaultEnable === true) await QUERY_API.preview.action(APP_URL.preview);
                const { data } = await QUERY_API.preview.checkStatus();
                if (!isCancelled) {
                    setIsPreviewEnable(data.isEnabled);
                    localStorage.setItem(LocalStorageKey.preview, `${data.isEnabled}`);
                    setIsLoading(false);
                }
            } catch (error) {
                if (!isCancelled) {
                    setIsError(true);
                    setIsLoading(true);
                }
            }
        })();
        return () => {
            isCancelled = true;
        };
    }, [defaultEnable]);

    return <>
        {isPreviewEnable !== undefined && !isError &&
            <div className="action-wrap">
                <>
                    <p className='action-title'>Preview: {isPreviewEnable ? 'on' : 'off'}</p>
                    {isPreviewEnable
                        ? <button onClick={handleActionBtnClick} className="action-btn btn btn-sm btn-gradient gradient-1 rounded-pill">
                            {isLoading && <Loading />} Disable
                        </button>
                        : <button onClick={handleActionBtnClick} className="action-btn btn btn-sm btn-gradient gradient-6 rounded-pill">
                            {isLoading && <Loading />} Enable
                        </button>
                    }
                    {children}
                </>
            </div>
        }

        {isError && console.error("Error when fetching data from [/api/check-preview-status]!")}
    </>;
};
