import { API_URL } from '@app/constants';
import { DirectusParams } from '@app/model';
import QS from 'qs';
import { axiosClient } from './axiosClient';
class DirectusDataLayer {
    async getList<T>(collection: string, params?: DirectusParams): Promise<T[]> {
        const query = QS.stringify(params);
        const res = await axiosClient.get(`${API_URL}/items/${collection}?${query}`);
        const resData = await res.data;
        return resData['data'];
    }
    async getItem<T>(collection: string, id: string, params?: DirectusParams): Promise<T> {
        const query = QS.stringify(params);
        const res = await axiosClient.get(`${API_URL}/items/${collection}/${id}?${query}`);
        const resData = await res.data;
        return resData['data'];
    }
}

export { DirectusDataLayer };
