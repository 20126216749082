import { MainLayout } from '@app/@gotecq-cms/layout';
import {
    AppScript, APP_DESCRIPTION,
    APP_NAME, SEO_URL, TWITTER_HANDLE,
    TWITTER_SITE
} from '@app/constants';
import { AppProvider } from '@app/context';
import { Site, TCategory, TMenu } from '@app/model';
import { CMS } from '@app/service';
import '@app/styles/index.scss';
import type { NextPageWithLayout } from '@app/types';
import { gtagUtils } from '@app/utils';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import React, { ReactNode, useEffect } from 'react';
import { SSRProvider } from 'react-bootstrap';
import '../static/css/plugins.css';
import '../static/css/style.css';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => {
    NProgress.done();
    window.scrollTo(0, 0);
});
Router.events.on('routeChangeError', () => NProgress.done());

type AppInitialCustomProps = {
    generalData: Site;
    menuData: TCategory[];
    quickLinks: TMenu[];
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
} & AppInitialCustomProps;

function MyApp({ Component, ...rest }: AppPropsWithLayout) {
    const router = useRouter();
    // Use the layout defined at the page level, if available
    const { pageProps, generalData, menuData, quickLinks } = rest;
    const pageLayoutProps = Component.pageLayoutProps;
    const getLayout = Component.getLayout
        ? (page: ReactNode) => page
        : (page: ReactNode) => (
            <MainLayout
                layoutProps={{ menuData, generalData, ...pageLayoutProps }}
            >
                {page}
            </MainLayout>
        );

    const additionalProps = {
        site_data: generalData,
        menu_items: menuData,
        quick_links: quickLinks,
    };

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            gtagUtils.pageView(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <>
            <AppScript />
            <DefaultSeo
                openGraph={{
                    type: 'website',
                    locale: 'en_US',
                    url: SEO_URL,
                    site_name: APP_NAME,
                    description: APP_DESCRIPTION,
                }}
                twitter={{
                    handle: TWITTER_HANDLE,
                    site: TWITTER_SITE,
                    cardType: 'summary_large_image',
                }}
            />
            <AppProvider
                value={{
                    generalData,
                    menuData,
                    metaData: {
                        quickLinks,
                    },
                }}
            >
                <SSRProvider>
                    {getLayout(<Component {...pageProps} {...additionalProps} />)}
                </SSRProvider>
            </AppProvider>
        </>
    );

}

MyApp.getInitialProps = async () => {
    const generalData = await CMS.getSiteInfo();
    const menuData = await CMS.getMenu();

    return {
        generalData,
        menuData,
        quickLinks: menuData,
    };
};

export default MyApp;