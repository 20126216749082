import { CMS_ENTRY_STATUS_PUBLISHED } from '@app/constants';
import React from 'react';

const MenuItem = ({
    title,
    link,
    subMenu = [],
    nestedItemsData = [],
    isMegaMenu,
}: {
    title?: string;
    link?: string;
    subMenu?: any;
    nestedItemsData?: { title: string; link: string }[];
    isMegaMenu?: boolean;
    menuItem?: any;
}) => {
    if (isMegaMenu) {
        return (
            <li className="nav-item">
                <a className="nav-link" data-bs-toggle="dropdown" href={link}>
                    <span className="nav-link-text">{title}</span>
                    <span className="nav-link-icon">
                        <svg
                            width="8"
                            fill="#fff"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                        </svg>
                    </span>
                </a>
                <MegaSubMenu items={subMenu} nestedItems={nestedItemsData} />
            </li>
        );
    }

    if (subMenu.length > 0 || nestedItemsData.length > 0) {
        return (
            <li className="nav-item dropdown">
                <a
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    href={link}
                >
                    <span className="nav-link-text">{title}</span>
                    <span className="nav-link-icon">
                        <svg
                            width="8"
                            fill="#fff"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                        </svg>
                    </span>
                </a>
                <NormalSubMenu items={subMenu} nestedItems={nestedItemsData} />
            </li>
        );
    }

    return (
        <li className="nav-item">
            <a className="nav-link" href={link}>
                {title}
            </a>
        </li>
    );
};

const DropdownMenuItem = ({
    title,
    link,
    subMenu = [],
    nestedItemsData = [],
}: any) => {
    if (subMenu.length > 0) {
        return (
            <li className="dropdown dropdown-menu-item">
                <a className="py-2 dropdown-item dropdown-toggle" href={link}>
                    {title}
                </a>
                <NormalSubMenu items={subMenu} nestedItems={nestedItemsData} />
            </li>
        );
    }

    return (
        <li className="nav-item dropdown-menu-item">
            <a className=" px-3 dropdown-item" href={link}>
                {title}
            </a>
        </li>
    );
};

export const MegaSubMenu = ({ items = [], nestedItems = [] }: any) => {
    let columns: any[] = [];
    let posts: any[] = [];
    let videos: any[] = [];
    let pages: any[] = [];

    items.map((item: any) => {
        const { title, pages } = item;
        if (Array.isArray(pages) && pages.length > 0) {
            const pageSorted = pages.sort((a, b) => a.order - b.order);
            const pagePublished = pageSorted.filter(
                (entry) => entry.status === CMS_ENTRY_STATUS_PUBLISHED
            );
            const pagesDataConvert = pagePublished.map(
                ({ title, slug }: any) => ({ title, link: `/page/${slug}` })
            );

            columns.push({ headerTitle: title, listItems: pagesDataConvert });
        } else {
            pages?.map((page: any) => {
                if (page.slug.includes('page')) {
                    pages.push(item);
                }
                if (page.slug.includes('video')) {
                    videos.push(item);
                }
                if (page.slug.includes('post')) {
                    posts.push(item);
                }
            });
        }
    });

    nestedItems.map((itemNested: any) => {
        if (itemNested.link.includes('page')) {
            pages.push(itemNested);
        }
        if (itemNested.link.includes('video')) {
            videos.push(itemNested);
        }
        if (itemNested.link.includes('post')) {
            posts.push(itemNested);
        }
    });

    if (posts.length > 0) {
        columns.push({ headerTitle: 'Posts', listItems: posts });
    }
    if (pages.length > 0) {
        columns.push({ headerTitle: 'Pages', listItems: pages });
    }
    if (videos.length > 0) {
        columns.push({ headerTitle: 'Videos', listItems: videos });
    }

    return (
        <ul className="dropdown-menu mega-menu">
            <li className="mega-menu-content container">
                <div className="row mx-0">
                    {columns.map((column, index) => {
                        return (
                            <div className="col-sm px-2" key={index}>
                                <h6 className="dropdown-header px-2">
                                    <div className="dropdown-header-title">
                                        {column.headerTitle}
                                    </div>
                                </h6>
                                <div className="row gx-0">
                                    <div className="col-sm">
                                        <ul className="list-unstyled">
                                            {column.listItems.map(
                                                (item: any, index: number) => {
                                                    return (
                                                        <li key={index}>
                                                            <a
                                                                className="dropdown-item px-2"
                                                                href={item.link}
                                                            >
                                                                {item.title}
                                                            </a>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </li>
        </ul>
    );
};

export const NormalSubMenu = ({ items = [], nestedItems = [] }: any) => {
    return (
        <ul className="dropdown-menu">
            {items.map((item: any, index: number) => {
                const { title, slug, pages } = item;

                return (
                    <DropdownMenuItem
                        key={index}
                        title={title}
                        link={`/category/${slug}`}
                        subMenu={pages}
                    />
                );
            })}

            {nestedItems.map((itemNested: any, index: number) => {
                const { title: itemTitle, link: nestedLink } = itemNested;
                return (
                    <DropdownMenuItem
                        key={index}
                        title={itemTitle}
                        link={nestedLink}
                    />
                );
            })}
        </ul>
    );
};

export const NavBarMenu = ({ menuData = [] }: any) => {
    if (menuData.length == 0) return null;

    return (
        <>
            {menuData.map((menuItem: any, index: number) => {
                if (!menuItem) return null;
                if (!menuItem.is_menu) return null;

                const { title, link } = menuItem;

                // if item has a link, link it directly
                const cat_template = menuItem.category_template ?? 'category';
                const {
                    children = [],
                    posts = [],
                    pages = [],
                    videos = [],
                } = menuItem;
                if (
                    children?.length > 0 ||
                    posts?.length > 0 ||
                    videos?.length > 0 ||
                    pages?.length > 0
                ) {
                    let $subMenu: any[] = [];
                    if (Array.isArray(pages)) {
                        pages
                            .filter(
                                (entry) =>
                                    entry.status === CMS_ENTRY_STATUS_PUBLISHED
                            )
                            .map(({ is_menu, title, slug, order }: any) => {
                                if (!is_menu) return;
                                $subMenu.push({
                                    title,
                                    link: `/page/${slug}`,
                                    order,
                                });
                            });
                    }
                    if (Array.isArray(posts)) {
                        posts
                            .filter(
                                (entry) =>
                                    entry.status === CMS_ENTRY_STATUS_PUBLISHED
                            )
                            .map(({ is_menu, title, slug, order }: any) => {
                                if (!is_menu) return;
                                $subMenu.push({
                                    title,
                                    link: `/post/${slug}`,
                                    order,
                                });
                            });
                    }
                    if (Array.isArray(videos)) {
                        videos
                            .filter(
                                (entry) =>
                                    entry.status === CMS_ENTRY_STATUS_PUBLISHED
                            )
                            .map(({ is_menu, title, slug, order }: any) => {
                                if (!is_menu) return;
                                $subMenu.push({
                                    title,
                                    link: `/video/${slug}`,
                                    order,
                                });
                            });
                    }

                    const link =
                        menuItem.link != 'NULL' && menuItem?.link?.length
                            ? menuItem.link
                            : `/${cat_template}/${menuItem.slug}`;

                    const subMenuOrdered = $subMenu.sort(
                        (a, b) => a.order - b.order
                    );

                    return (
                        <MenuItem
                            key={index}
                            link={link}
                            title={title}
                            subMenu={children}
                            nestedItemsData={subMenuOrdered}
                            isMegaMenu={!!children?.length}
                        />
                    );
                }

                return <MenuItem key={index} title={title} link={link} />;
            })}
        </>
    );
};
