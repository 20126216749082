import { TMenu } from '@app/model';
import React from 'react';
import { NavbarLayout } from '../navbar';
import { TopBar } from '../navbar/topbar';

export const HeaderLayout = ({ menuData }: { menuData: TMenu[] }) => {
    return (
        <header className="wrapper bg-light" id="header">
            <NavbarLayout menuData={menuData} />
            <TopBar />
        </header>
    );
};
