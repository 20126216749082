/* eslint-disable no-console */
import { DirectusDataLayer } from '@app/@gotecq-cms/data-layer';

import {
    APP_DIRECTUS_KEY,
    APP_NAME,
    APP_DESCRIPTION,
    APP_KEYWORDS,
} from '@app/constants';
import { DirectusParams, Fragment, Page, Post, Resource, Site, TCategory, TSideBarCategory, Video } from '@app/model';

export const CMSData = new DirectusDataLayer();

export const CMS = {
    /**
     * return the site info, such as title, description from CMS
     * under cms->sites
     */
    getSiteInfo: async () => {
        try {
            const siteInfo = await CMSData.getItem<Site>(`site`, APP_DIRECTUS_KEY, {
                fields: [`*`],
            });

            return siteInfo;
        } catch (error) {
            console.log(
                `ERROR: Couldnt retrieve site info from API, use default Site Info instead**`
            );
            return {
                key: APP_DIRECTUS_KEY,
                name: APP_NAME,
                description: APP_DESCRIPTION,
                keywords: APP_KEYWORDS,
            };
        }
    },

    /**
     * Return top X of latest articles
     * @param {number} max_num
     */
    getLatestArticles: async (max_num?: number) => {
        const default_max = 6;
        try {
            const articles = await CMSData.getList<Post>(`post`, {
                sort: `-created_on`,
                limit: max_num ?? default_max,
                fields: [`*`, `photo.id`],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    photo: { _nnull: true },
                },
            });

            return articles;
        } catch (error) {
            console.log(`ERROR: Error fetching Latest Articles`);
            return null;
        }
    },

    /**
     * Get Home page sections, could pass in an array of sections if needed
     */
    getHomePageSections: async () => {
        try {
            const home_sections = await CMSData.getList<Fragment>(`fragment`, {
                fields: [`*`, `photo.*`],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    section: { _nnull: true },
                },
                sort: ['section', 'custom_sort'],
            });

            const sections = home_sections.reduce((prev, curr): { [key: string]: Fragment[] } => {
                const sectionKey = curr?.section?.replace(/\-/g, '_');
                prev[sectionKey as string] = [...prev[sectionKey as string] || [], curr];
                return prev;
            }, {} as { [key: string]: Fragment[] });

            return sections;
        } catch (error) {
            console.log(
                `ERROR: Couldn't retrieve Home Page Sections from API**`, error
            );
            return null;
        }
    },

    /**
     * Retrieve a post by it`s Slug value
     * @param {string} slug
     */
    getPostBySlug: async (slug: string) => {
        try {
            const page_data = await CMSData.getList<Post>(`post`, {
                fields: [`*`, `owner.*`, `modified_by.*`, `parent.*`],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    slug: { _eq: slug },
                },
                limit: 1,
            });

            return page_data[0] ?? false;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve page by Slug from API*`);
            return null;
        }
    },

    /**
     * Retrieve a post by it`s Slug value
     * @param {string} slug
     */
    getFragmentBySlug: async (slug: string) => {
        try {
            const page_data = await CMSData.getList<Fragment>(`fragment`, {
                fields: [`*`],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    slug: { _eq: slug },
                },
                limit: 1,
            });

            return page_data[0] ?? false;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve Fragment by Slug from API*`);
            return null;
        }
    },

    /**
     * Retrieve a Page by it`s Slug value
     * @param {string} slug
     */
    getVideoBySlug: async (slug: string) => {
        try {
            const page_data = await CMSData.getList<Video>(`video`, {
                fields: [`*`, `related_videos.*`],
                site: { _eq: APP_DIRECTUS_KEY },
                filter: { status: { _eq: 'published' }, slug: { _eq: slug } },
                limit: 1,
            });

            return page_data[0] ?? false;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve Video By Slug from API*`);
            return null;
        }
    },

    /**
     * Retrieve a Page by it`s Slug value
     * @param {number} item_id
     * @param {number} cat_id
     */
    getSimilarVideos: async (item_id: number, cat_id: number) => {
        try {
            const page_data = await CMSData.getList<Video>(`video`, {
                fields: [`*`],
                filter: {
                    status: { _eq: 'published' },
                    category: { _eq: cat_id },
                    id: { _neq: item_id },
                },
                limit: 3,
            });

            return page_data;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve Similar Videos from API*`);
            return null;
        }
    },

    /**
     * Retrieve a Page by it`s Slug value
     * @param {string} slug
     */
    getPageBySlug: async (slug: string, status: 'published' | 'draft' = 'published') => {
        try {
            const page_data = await CMSData.getList<Page>(`page`, {
                fields: [`*`, `owner.*`, `videos.*`],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: status },
                    slug: { _eq: slug },
                },
                limit: 1,
            });

            return page_data[0] ?? false;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve page by slug from API*`);
            return null;
        }
    },

    /**
     * Retrieve a Pages by it`s Slug value
     * @param {string} slug
     */
    getPagesBySlug: async (slug: string) => {
        try {
            const pages = await CMSData.getList<Page>(`page`, {
                fields: [`*`, `owner.*`, `modified_by.*`],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    slug: { _eq: slug },
                },
            });

            return pages;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve pages by Slug from API*`);
            return null;
        }
    },

    /**
     * Retrieve children categories
     * @param {number} id
     */
    getChildrenCategories: async (id: number) => {
        try {
            const pages = await CMSData.getList<TCategory>(`category`, {
                fields: ['*', 'photo.*', 'pages.*'],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    parent: { _eq: id },
                },
            });

            return pages;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve pages by Category ID from API*`);
            return null;
        }
    },

    /**
     * Retrieve a Pages by it`s Slug value
     * @param {number} id
     */
    getPagesByCategoryId: async (id: number) => {
        try {
            const pages = await CMSData.getList<Page>(`page`, {
                fields: ['*', 'photo.*'],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    category: { _eq: id },
                },
            });

            return pages;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve pages by Category ID from API*`);
            return null;
        }
    },

    /**
     * Retrieve a Pages by it`s Slug value
     * @param {number} id
     */
    getRelatedPostsByCategoryId: async (id: number) => {
        try {
            const posts = await CMSData.getList<TCategory>(`category`, {
                fields: ['*.*.*'],
                filter: { site: { _eq: APP_DIRECTUS_KEY }, id: { _eq: id } },
            });

            return posts;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve related Posts by Category ID from API`);
            return null;
        }
    },

    /**
     * Retrieve a Pages by it`s Slug value
     * @param {number} id
     */
    getPostsByCategoryId: async (id: number) => {
        try {
            const posts = await CMSData.getList<Post>(`post`, {
                fields: ['*', 'photo.*'],
                filter: { site: { _eq: APP_DIRECTUS_KEY }, category: { _eq: id } },
            });

            return posts;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve Posts by Category ID from API`);
            return null;
        }
    },

    /**
     * Retrieve a Pages by it`s Slug value
     * @param {string} slug
     */
    // eslint-disable-next-line no-unused-vars
    getPagesByCategory: async (slug: string) => {
        try {
            const pages = await CMSData.getList<TCategory>(`category`, {
                fields: [
                    '*',
                    'children.*',
                    'children.photo.*',
                    'children.posts.*',
                    'posts.*',
                    'posts.photo.*',
                    'photo.*',
                    'children.pages.*',
                    'pages.*',
                    'pages.photo.*',
                ],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    slug: { _eq: `claim` },
                },
            });

            return pages;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve pages by Category Slug from API`);
            return null;
        }
    },

    /**
     * Retrieve a Pages by it`s Slug value
     * @param {string} slug
     */
    getCategory: async (slug: string) => {
        try {
            const category = await CMSData.getList<TCategory>(`category`, {
                fields: ['*'],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    category_template: { _eq: 'category' },
                    slug: { _eq: slug },
                },
            });

            return category[0] ?? null;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve Categories from API`);
            return null;
        }
    },

    /**
     * Retrieve a video category
     * @param {string} slug
     */
    getVideosCategory: async (slug: string) => {
        try {
            const category = await CMSData.getList<TCategory>(`category`, {
                fields: ['*', 'videos.*'],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    category_template: { _eq: 'videos' },
                    slug: { _eq: slug },
                },
            });

            return category[0] ?? null;
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve Video Category from API`);
            return null;
        }
    },

    /**
     * Get common forms section
     */
    getSideBarCommonForms: async () => {
        try {
            const common_forms = await CMSData.getList<Fragment>(`fragment`, {
                fields: [`*`],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    section: { _eq: `common-forms` },
                },
                sort: `-created_on`,
            });
            return common_forms;
        } catch (error) {
            console.log(
                '🚀 ~ file: cms.tsx ~ line 422 ~ getSideBarCommonForms: ~ error',
                error
            );
            return null;
        }
    },

    /**
     * Get Categories for the side bar menu
     */
    getSideBarCategory: async () => {
        try {
            const sidebar_menu_items = await CMSData.getList<TSideBarCategory[]>(`category`, {
                filter: {
                    is_side_bar: { _nnull: true },
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                },
                sort: [`order`],
                fields: [
                    `id`,
                    'title',
                    'link',
                    'slug',
                    `is_side_bar`,
                    `pages.id`,
                    `pages.status`,
                    `pages.title`,
                    `pages.slug`,
                ],
            });
            return sidebar_menu_items;
        } catch (error) {
            console.log(
                `🚀 ~ file: fetcher.ts ~ line 48 ~ getCategories: ~ error`,
                error
            );
            return null;
        }
    },

    /**
     * get all the menu to render the menu on the navigation page
     */
    getMenu: async () => {
        try {
            const menu_items = await CMSData.getList(`category`, {
                filter: {
                    parent: { _null: true },
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                },
                sort: [`order`],
                fields: [
                    `*`,
                    `children.id`,
                    `children.title`,
                    `children.slug`,
                    `children.parent`,
                    `children.is_menu`,
                    `children.posts.*`,
                    `children.videos.*`,
                    `children.category_template`,
                    `posts.title`,
                    `posts.id`,
                    `posts.slug`,
                    `posts.link`,
                    `children.pages.*`,
                    `pages.id`,
                    `pages.status`,
                    `pages.title`,
                    `pages.slug`,
                    `pages.is_menu`,
                    `pages.order`,
                    `videos.*`,
                ],
            });
            return menu_items;
        } catch (error) {
            console.log(
                `🚀 ~ file: fetcher.ts ~ line 48 ~ getCategories: ~ error`,
                error
            );
            return null;
        }
    },

    getPathsBySection: async ({
        collection,
        section_name,
    }: {
        collection: string;
        section_name: string;
    }) => {
        try {
            let query_part = {
                fields: [`*`],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    slug: { _nnull: true },
                    status: { _eq: 'published' },
                    section: { _eq: section_name },
                },
            };

            const all_pages = await CMSData.getList<Fragment>(collection, query_part);

            // construct all the path on the pages based on the slug
            const all_paths = all_pages?.map((post) => {
                if (typeof post['slug'] !== 'undefined') {
                    return {
                        params: { ['slug']: `${post['slug']}` },
                    };
                }
            });

            return all_paths;
        } catch (error) {
            return null;
        }
    },

    getCategoryPaths: async () => {
        try {
            let query_part = {
                fields: [`*`],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    category_template: { _eq: 'category' },
                    slug: { _nnull: true },
                },
            };

            const itemKey = 'slug';
            const key = 'slug';
            const all_pages = await CMSData.getList<TCategory>('category', query_part);

            // construct all the path on the pages based on the slug
            const all_paths = all_pages?.map((post) => {
                if (typeof post[itemKey] !== 'undefined') {
                    return {
                        params: { [key]: `${post[itemKey]}` },
                    };
                }
            });

            return all_paths;
        } catch (error) {
            return null;
        }
    },

    getPaths: async <T>({
        collection,
        key,
        itemKey,
        ignoreSite = false,
    }: {
        collection: string;
        key: string;
        itemKey: string;
        ignoreSite: boolean;
    }) => {
        try {
            let query_part = {
                fields: [`*`],
                filter: {
                    site: { _eq: APP_DIRECTUS_KEY },
                    status: { _eq: 'published' },
                    slug: { _nnull: true },
                },
            } as DirectusParams;

            if (ignoreSite) {
                delete query_part.filter?.site;
            }

            const all_pages = await CMSData.getList<Record<string, T>>(collection, query_part);

            // construct all the path on the pages based on the slug
            const all_paths = all_pages?.map((post) => {
                if (post[itemKey]) return { params: { [key]: `${post[itemKey]}` } };
            });

            return all_paths;
        } catch (error) {
            return null;
        }
    },

    getVideosPaths: async () => {
        let query_part = {
            fields: [`*`, `children.*`],
            filter: {
                site: { _eq: APP_DIRECTUS_KEY },
                status: { _eq: 'published' },
                category_template: { _eq: 'videos' },
                slug: { _nnull: true },
            },
        };

        const itemKey = 'slug';
        const key = 'slug';
        try {
            const all_pages = await CMSData.getList<TCategory>('category', query_part);
            let all_paths: { params: { slug: string } }[] = [];
            // construct all the path on the pages based on the slug
            all_pages?.map((post) => {
                if (post.children) {
                    post?.children?.map((video_cat) => {
                        all_paths.push({
                            params: { [key]: `${video_cat[itemKey]}` },
                        });
                    }
                    );
                }
            });
            return all_paths;
        } catch (error) {
            console.log(`ERROR: cannot get video paths`, error);
            return null;
        }
    },

    /**
     * Retrieve a Page by it`s Slug value
     */
    getAllResource: async () => {
        try {
            const resources_data = await CMSData.getList<Resource>(`resource`, {
                fields: [`*`],
                site: { _eq: APP_DIRECTUS_KEY },
                filter: { status: { _eq: 'published' } },
            });

            return resources_data ?? [];
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve Resource By Slug from API*`);
            return null;
        }
    },
    getFragmentByResourceId: async (resourceId: string) => {
        try {
            const resources_data = await CMSData.getList<Fragment>(`fragment`, {
                fields: [`*`],
                site: { _eq: APP_DIRECTUS_KEY },
                filter: {
                    section: { _eq: resourceId },
                    status: { _eq: 'published' }
                },
                sort: 'custom_sort'
            });

            return resources_data ?? [];
        } catch (error) {
            console.log(`ERROR: Couldn't retrieve Resource By Slug from API*`);
            return null;
        }
    },
};
