import React, { useEffect } from 'react';

import type { NavMenuItem, PostModel } from '@app/types';
import Link from 'next/link';
import { NavBarMenu } from './menu';
import { TMenu, Video } from '@app/model';

function RenderMenu({
    menu,
    isSubMenu,
    isTopMenu
}: { isSubMenu?: boolean; isTopMenu?: boolean; menu: NavMenuItem[] }) {
    return <>
        {menu.map((menu_item, index) => {
            // if is_menu is not selected, then return

            if (!menu_item.is_menu) {
                return null;
            }

            // if item has a link, link it directly
            const cat_template = menu_item.category_template ?? 'category';
            const { children, posts, pages, videos } = menu_item ?? {};

            // if this has children and pages,
            if (
                (children && children?.length > 0) ||
                (posts && posts?.length > 0) ||
                (pages && pages?.length > 0) ||
                (videos && videos?.length > 0)
            ) {
                const link_or_slug =
                    menu_item.link != 'NULL' && menu_item?.link?.length
                        ? menu_item.link
                        : `/${cat_template}/${menu_item.slug}`;

                return (
                    <li
                        className={
                            isSubMenu ? 'dropdown item-lv2' : 'dropdown nav-item'
                        }
                        key={index}
                    >
                        <Link href={link_or_slug}>
                            <a
                                id='menu-nav-link'
                                href={link_or_slug}
                                className={`${isSubMenu
                                    ? 'py-2 dropdown-item '
                                    : 'nav-link dropdown-toggle'
                                    }`}
                            >
                                {menu_item.title}
                                {isTopMenu && <span className="sub-arrow" />}
                            </a>
                        </Link>
                        <ul className="dropdown-menu border">
                            <RenderMenu menu={children ?? []} isSubMenu />
                            {renderMenuPost(posts ?? [], true)}
                            {renderMenuPage(pages ?? [])}
                            {renderMenuVideo(videos ?? [])}
                        </ul>
                    </li>
                );
            }

            return menu_item.link !== 'NULL' && menu_item?.link?.length ? (
                <li key={index} className="nav-item item-lv-1">
                    <Link href={menu_item.link}>
                        <a className="nav-link scrollto">{menu_item.title}</a>
                    </Link>
                </li>
            ) : (
                // if item has a slug render it as internal link
                <li key={index} className="nav-item item-lv-1">
                    <Link href={`/${cat_template}/${menu_item.slug}`}>
                        <a className="nav-link scrollto">{menu_item.title}</a>
                    </Link>
                </li>
            );
        })}
    </>;
}

function renderMenuPost(posts: any[], isLevel3: boolean) {
    // posts.sort(Compare);

    return posts.map((post, index) => {
        if (!post.is_menu || post.status != 'published') return null;
        return (
            <li className="dropdown" key={index}>
                <Link href={`/post/${post.slug}`}>
                    <a className="py-2 dropdown-item">{post.title}</a>
                </Link>
            </li>
        );
    });
}

function renderMenuVideo(videos: Video[]) {
    return videos.map((video, index) => {
        if (!video.is_menu || video.status != 'published') return null;
        return (
            <li className="nav-item" key={index}>
                <Link href={`/video/${video.slug}`}>
                    <a className="py-2 dropdown-item">{video.title}</a>
                </Link>
            </li>
        );
    });
}

function renderMenuPage(pages: any[]) {
    return pages.map((page, index) => {
        if (!page.is_menu || page.status !== 'published') return null;
        return (
            <li className="nav-item" key={index}>
                <Link href={`/page/${page.slug}`}>
                    <a className="py-2 dropdown-item">{page.title}</a>
                </Link>
            </li>
        );
    });
}

export const NavbarLayout = ({ menuData }: { menuData: TMenu[] }) => {
    return (
        <nav
            className="site-navbar navbar navbar-expand-lg center-nav navbar-dark navbar-bg-dark text-white shadow-lg"
            id="tecqpartners-navbar"
        >
            <div className="container flex-lg-row flex-nowrap align-items-center mx-xl-auto">
                <div className="navbar-brand w-100">
                    {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                    <a className="h-100 w-100" href="/">
                        <img
                            src="/img/tecq-foundation-logo.svg"
                            alt=""
                        />
                    </a>
                </div>
                <div className="navbar-collapse offcanvas-nav">
                    <div className="offcanvas-header d-lg-none d-xl-none">
                        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                        <a
                            className="d-inline-block h-100 w-100 pe-6"
                            href="/"
                        >
                            <img
                                height="100%"
                                src="/img/tecq-foundation-logo.svg"
                                alt=""
                            />
                        </a>
                        {/* <button
                            type="button"
                            className="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
                            aria-label="Close"
                        ></button> */}
                    </div>
                    {
                        <ul className="navbar-nav">
                            <NavBarMenu
                                menuData={menuData}
                                megaMenu
                            />
                            {/* {menuData && <RenderMenu menu={menuData} isTopMenu />} */}
                        </ul>
                    }
                </div>
                <div className="navbar-other d-flex">
                    <ul
                        className="navbar-nav flex-row align-items-center ms-auto"
                        data-sm-skip="true"
                    >
                        <li className="nav-item d-lg-none">
                            <div className="navbar-hamburger">
                                <button
                                    className="hamburger animate plain pe-auto"
                                    data-toggle="offcanvas-nav"
                                >
                                    <span></span>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
