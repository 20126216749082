import { GA_TRACKING_ID } from '@app/constants';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const pageView = (url: string) => {
    if (!GA_TRACKING_ID) return;

    window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
    });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const event = ({ action, category, label, value }: { action: string; category: string; label: string; value: string }) => {
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};

export const gtagUtils = { pageView, event };