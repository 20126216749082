import getConfig from 'next/config';

// Only holds serverRuntimeConfig and publicRuntimeConfig
const { publicRuntimeConfig } = getConfig?.() ?? { publicRuntimeConfig: {} };

// eslint-disable-next-line no-unused-vars
export function ENV(key: string, def: string | ((arg: string) => string) = '') {
    let value = def;
    if (typeof def === 'function') {
        value = def(publicRuntimeConfig?.[key] || '');
    }
    return publicRuntimeConfig?.[key] || value;
}