import { ASSETS_URL } from '@app/constants';
import { validate as validateUUID } from "uuid";

export const getAssetFilePath = (fileId: string) => {
    if (!validateUUID(fileId)) {
        throw new Error('File id is not valid!!');
    }

    return `${ASSETS_URL}/${fileId}`;
};

export { gtagUtils } from './gtag';