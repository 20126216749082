export * from './env';
export * from './data-response';

export { AppScript } from './app-script';

export const PreviewModeURL: string[] = JSON.parse(process.env.NEXT_PUBLIC_PREVIEW_MODE_URL ?? '[]');

export const BaseAPIURL = '/api/';
export const APP_URL = {
    previewStatus: 'check-preview-status',
    preview: 'preview',
    clearPreview: 'clear-preview',
};

export const LocalStorageKey = {
    preview: 'previewMode'
};
export const CMSCollection = {
    PAGE: 'page',
    POST: 'post',
    CATEGORY: 'category',
    VIDEO: 'video',
    FRAGMENT: 'fragment'
};
export const CMSBaseURL = `${process.env.NEXT_PUBLIC_API_URL}/admin/content`;
export const CMS_ENTRY_STATUS_PUBLISHED = 'published';